import '../sass/project.scss';

document.addEventListener('DOMContentLoaded', function () {
  function expandSidebar(event) {
    const sidebar = event.currentTarget;
    sidebar.dataset.originalWidth = sidebar.offsetWidth;
    let childWidth = 0;
    Array.from(sidebar.children).forEach((child) => {
      const childRect = child.getBoundingClientRect();
      if (childRect.width > childWidth) {
        childWidth = childRect.width;
      }
    });
    const newWidth = Math.max(192, childWidth);
    sidebar.style.width = newWidth + 'px';
  }

  function collapseSidebar(event) {
    const sidebar = event.currentTarget;
    if (sidebar.dataset.originalWidth) {
      sidebar.style.width = sidebar.dataset.originalWidth + 'px';
      delete sidebar.dataset.originalWidth;
    }
  }

  const sidebars = document.querySelectorAll('.app-leftbar, .app-rightbar');
  sidebars.forEach((sidebar) => {
    sidebar.addEventListener('mouseenter', expandSidebar);
    sidebar.addEventListener('mouseleave', collapseSidebar);
  });

  const backButton = document.getElementById('app-back-button');
  const forwardButton = document.getElementById('app-forward-button');

  backButton.onclick = function () {
    window.history.back();
  };

  forwardButton.onclick = function () {
    window.history.forward();
  };
});
